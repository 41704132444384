/* !Color Scheme:
?#cad2c5 : text
?#84a98c : text darker
?#52796f : middle
?#354f52 : background higlighted
?#2f3e46 : background
*/

* {
    box-sizing: border-box;
    ::-moz-selection {
        /* Code for Firefox */
        color: black;
        background: rgb(90, 90, 199);
    }

    ::selection {
        color: black;
        background: rgb(90, 90, 199);
    }
}

body {
    font-family: Arial, Helvetica, sans-serif;
    background-color: #2f3e46;
    color: #cad2c5;
}

.btn {
    background-color: #84a98c;
    height: max-content;
    font-weight: bold;
    cursor: pointer;
}

.btn:hover {
    background-color: #52796f;
}

.highlighted {
    font-weight: bold;
    background-color: #cad2c5;
    color: #2f3e46;
    width: max-content;
}

h1 {
    text-align: center;
    font-style: italic;
    background-color: #cad2c5;
    color: #2f3e46;
}

h3 {
    background-color: #cad2c5;
    color: #2f3e46;
    width: max-content;
    padding: 0px 10px 0px 10px;
}
.element {
    display: flex;
    width: 200px;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 15px 5px 15px;
    margin: 10px 10px 10px 0px;
    align-items: center;
}

.enclosingDiv {
    display: flex;
    flex-direction: row;
}
